var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-layout',{staticClass:"mb-5",attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{attrs:{"grey":"","darken-10":"","height":"40"}},[_c('marquee',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(" Welcome To Task Management Systemm ")])])],1)],1)],1),(_vm.ifResponse)?[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"my-2 mr-2",attrs:{"xs6":"","sm12":""}},[_c('v-card',[_c('v-layout',{attrs:{"justify-center":""}},[_c('v-tooltip',{attrs:{"right":"","color":"blue"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","color":"primary"}},on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("pending_actions")])],1)]}}],null,false,2483755800)},[_c('span',[_vm._v("Pending Tasks")])])],1),_c('v-layout',{attrs:{"justify-center":""}},[_c('b',{staticClass:"title-class",attrs:{"color":"red"}},[_vm._v("TOTAL PENDING TASKS: "),(_vm.getAllTaskItem.hasOwnProperty('pending'))?_c('span'):_vm._e(),_vm._v(_vm._s(Object.keys(_vm.getAllTaskItem['pending']).length))])])],1)],1),_c('v-flex',{staticClass:"my-2 ml-2",attrs:{"xs6":"","sm12":""}},[_c('v-card',[_c('v-layout',{attrs:{"justify-center":""}},[_c('v-tooltip',{attrs:{"right":"","color":"blue"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","color":"deep-purple"}},on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("pending_actions")])],1)]}}],null,false,2134104419)},[_c('span',[_vm._v("In Progress Tasks")])])],1),_c('v-layout',{attrs:{"justify-center":""}},[_c('b',{staticClass:"title-class",attrs:{"color":"red"}},[_vm._v("TOTAL IN PROGRESS TASKS: "),(_vm.getAllTaskItem.hasOwnProperty('progress'))?_c('span'):_vm._e(),_vm._v(_vm._s(Object.keys(_vm.getAllTaskItem['progress']).length))])])],1)],1)],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"my-2 mr-2",attrs:{"xs6":"","sm12":""}},[_c('v-card',[_c('v-layout',{attrs:{"justify-center":""}},[_c('v-tooltip',{attrs:{"right":"","color":"blue"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","color":"purple"}},on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("pending_actions")])],1)]}}],null,false,928956506)},[_c('span',[_vm._v("Testing Tasks")])])],1),_c('v-layout',{attrs:{"justify-center":""}},[_c('b',{staticClass:"title-class",attrs:{"color":"red"}},[_vm._v("TOTAL TESTING TASKS: "),(_vm.getAllTaskItem.hasOwnProperty('testing'))?_c('span'):_vm._e(),_vm._v(_vm._s(Object.keys(_vm.getAllTaskItem['testing']).length))])])],1)],1),_c('v-flex',{staticClass:"my-2 ml-2",attrs:{"xs6":"","sm12":""}},[_c('v-card',[_c('v-layout',{attrs:{"justify-center":""}},[_c('v-tooltip',{attrs:{"right":"","color":"blue"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","color":"green"}},on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("pending_actions")])],1)]}}],null,false,209803311)},[_c('span',[_vm._v("Done Tasks")])])],1),_c('v-layout',{attrs:{"justify-center":""}},[_c('b',{staticClass:"title-class",attrs:{"color":"red"}},[_vm._v("TOTAL DONE TASKS: "),(_vm.getAllTaskItem.hasOwnProperty('done'))?_c('span'):_vm._e(),_vm._v(_vm._s(Object.keys(_vm.getAllTaskItem['done']).length))])])],1)],1)],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }